<template>
  <div class='md-layout text-center'>
    <div
        class='md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100'
    >
      <login-card header-color='danger'>
        <h4 slot='title' class='title'>{{ $t('label.app_title') }}</h4>
        <div
            v-for='(lang, index) in locales'
            :key='lang.code'
            slot='lang-name'
            class='lang-name-box'
        >
          <span
              :class="{
              'lang-name-active': lang.code === active_locale,
              'lang-div': index !== 0
            }"
              class='lang-name'
              @click='onLangChange(lang.code)'
          >
            {{ lang.code }}
          </span>
        </div>
        <p slot='description' class='description'>{{ $t('login.title') }}</p>
        <form id='login_form' slot='form' @submit.prevent='validate'>
          <md-field
              slot='inputs'
              :class="[
              {'md-valid': !errors.has('login') && touched.login},
              {'md-error': errors.has('login')}
            ]"
              class='md-form-group'>
            <md-icon>face</md-icon>
            <label>{{ $t('login.user_login') }}</label>
            <md-input
                v-model='login'
                v-validate='modelValidations.login'
                data-vv-name='login'
                required
                type='login'>
            </md-input>
            <slide-y-down-transition>
              <md-icon v-show="errors.has('login')" class='error'>
                close
              </md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                  v-show="!errors.has('login') && touched.login"
                  class='success'
              >
                done
              </md-icon>
            </slide-y-down-transition>
          </md-field>
          <md-field
              slot='inputs'
              :class="[
              {'md-error': errors.has('password')},
              {'md-valid': !errors.has('password') && touched.password}
            ]"
              class='md-form-group'>
            <md-icon>lock_outline</md-icon>
            <label>{{ $t('login.user_password') }}</label>
            <md-input
                v-model='password'
                v-validate='modelValidations.password'
                autocomplete='on'
                data-vv-name='password'
                required
                type='password'></md-input>
            <slide-y-down-transition>
              <md-icon v-show="errors.has('password')" class='error'>
                close
              </md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                  v-show="!errors.has('password') && touched.password"
                  class='success'
              >
                done
              </md-icon>
            </slide-y-down-transition>
          </md-field>
          <div class='md-error'>{{ errMessage }}</div>
          <div class='md-layout'>
            <div class='md-layout-item'>
              <md-button
                  slot='footer'
                  class='md-simple md-success md-lg'
                  form='login_form'
                  type='submit'
              >
                {{ $t('login.enter') }}
              </md-button>
            </div>
          </div>
          <a href @click.stop.prevent="onPassModalShow" class="md-caption">
            {{ $t('label.lnkPassRec') }}
          </a>
        </form>
      </login-card>
    </div>
    <modal
        v-if="passwdModalShow"
        @close="passwdModalHide"
        class="small-alert-modal"
    >
      <template slot="header">
        <h4 class="modal-title modal-recovery">{{ $t('label.lnkPassRec') }}</h4>
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="passwdModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <p class="modal-recovery">{{ $t('info.forgot_pass_descr') }}</p>
        <md-field
            :class="[
            {'md-valid': !errors.has('reqEmail') && touched.reqEmail},
            {'md-error': errors.has('reqEmail')}
          ]"
        >
          <label>{{ $t('label.email') }}</label>
          <md-input
              v-model="reqEmail"
              data-vv-name="reqEmail"
              type="email"
              required
              v-validate="modelValidations.reqEmail"
          ></md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('reqEmail')">
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
                class="success"
                v-show="!errors.has('reqEmail') && touched.reqEmail"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
      </template>

      <template slot="footer">
        <md-button class="md-success" @click="onReqPassRecovery">
          {{ $t('button.send') }}
        </md-button>
      </template>
    </modal>
  </div>
</template>
<script>

import {LoginCard, Modal} from '@/components'
import {SlideYDownTransition} from 'vue2-transitions'
import {mapGetters, mapState} from 'vuex'
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
    Modal,
    SlideYDownTransition
  },
  data() {
    return {
      passwdModalShow: false,
      login: null,
      password: null,
      reqEmail: '',
      errMessage: '',
      touched: {
        login: false,
        password: false,
        reqEmail: false
      },
      modelValidations: {
        login: {
          required: true
        },
        reqEmail: {
          required: true,
          email: true
        },
        password: {
          required: true,
          min: 5
        }
      }
    }
  },
  watch: {
    password() {
      this.touched.password = true
    },
    reqEmail() {
      this.touched.reqEmail = true
    }
  },
  methods: {
    passwdModalHide() {
      this.passwdModalShow = false
    },
    onPassModalShow() {
      this.reqEmail = ''
      this.passwdModalShow = true
    },
    onReqPassRecovery() {
      const alert = {
        type: 'success',
        text: this.$t(`message.we_sent_you_an_email_with_instructions_to_reset_your_password`),
        footer: ''
      }

      this.$validator.validate('reqEmail').then(async (isValid) => {
        if (isValid) {
          this.passwdModalHide()
          const email = this.reqEmail
          const locale = this.$i18n.locale
          try {
            await this.$store.dispatch('PASSWORD_RESET_REQUEST', {email, locale})
            Swal.fire(alert)
          } catch (err) {
            alert.type = 'error'
            alert.text = err
          } finally {
            Swal.fire(alert)
          }

        }
      })

      this.touched.reqEmail = true

      if (!this.reqEmail) {
        return
      }
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.onEnter()
        }
        //this.$emit('on-submit', this.registerForm, isValid)
      })

      this.touched.login = true
      this.touched.password = true
      this.touched.reqEmail = true
    },
    async onEnter() {
      this.errMessage = ''
      const {login, password} = this
      try {
        await this.$store.dispatch('LOGIN', {login, password})
        if (this.authStatus === 'success') {
          const path = this.pathAfterLogin && this.pathAfterLogin !== '/page-not-found' ? this.pathAfterLogin : '/dashboard?tab=1'
          this.$store.commit('SET_REDIRECT_AFTER_LOGIN', '')
          this.$router.push(path)
        } else if (this.authStatus === 'error') {
          this.errMessage = this.$t('info.auth_error')
          this.password = ''
        }
      } catch (err) {
        console.log(err)
      }

    },
    async onLangChange(code) {
      if (!this.isLangLoginMessagesExist(code)) {
        await this.$store.dispatch('LOAD_LOGIN_TRANSLATE', code)
      }
      this.$store.commit('CHANGE_LANG', code)
    }
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.Login.authStatus,
      active_locale: (state) => state.i18nStore.active,
      locales: (state) =>
          state.i18nStore.locales.sort((a, b) => a.index - b.index),
      pathAfterLogin: (state) => state.Login.pathAfterLogin
    }),
    ...mapGetters({
      isLangLoginMessagesExist: 'isLangLoginMessagesExist'
    })
  }
}

</script>

<style lang='scss'>
@import 'src/assets/scss/md/_variables.scss';


.md-error {
  color: $brand-danger;
  //margin-bottom: 5px;
  min-height: 30px;
}

.lang-name-box {
  min-width: 45px;
  text-align: center;

  .lang-name {
    padding: 0 10px;
    font-size: 1.8em;
    color: $grey-400;
    cursor: pointer;

    &:hover {
      color: $brand-danger;
    }
  }

  .lang-div {
    border-left: 1px solid $grey-200;
  }

  .lang-name-active {
    color: $brand-success;

    &:hover {
      color: $brand-success;
    }
  }
}

.modal-recovery {
  color: $grey-700;
}
</style>
